const fetch = require('isomorphic-unfetch');
const paramsConfig = require('../config/params');
const { MAX_PAGES_COUNT_FOR_SINGLE_QUERY } = require('../config/datoCMS');

const requestDataFromDatoCMS = async (query, cmsApiUrl, cmsReadonlyApiToken) => {
  const res = await fetch(cmsApiUrl, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${cmsReadonlyApiToken}`,
    },
    body: JSON.stringify({
      query,
      variables: {},
    }),
  });

  const json = await res.json();

  if (json.errors) {
    console.debug(json.errors);
    throw new Error('Failed to fetch routes structure from DatoCMS');
  }

  return json.data;
};

const createAllLocalePagesCountQuery = (locale) => `
  query AllPagesSize {
    _allPagesMeta(locale: ${locale}) {
      count
    }
  }
`;

const createAllLocalePagesDataQuery = (locale, first = MAX_PAGES_COUNT_FOR_SINGLE_QUERY, skip = 0) => `
  query AllPages {
    allPages(
      locale: ${locale}, 
      orderBy: id_DESC, 
      first: ${first}, 
      skip: ${skip},
    ) {
      id
      isFolder
      title
      layout
      slug
      parent {
        id
        isFolder
      }
    }
  }
`;

const createCoreRoutesIdsQuery = () => `
  query CoreRoutesIds {
    route {
      loginPage {
        id
      }
      homePage {
        id
      }
      deliveryAddressesPage {
        id
      }
      businessUserResetPasswordPage {
        id
      }
      businessUserRegisterPage {
        id
      }
      businessUserLoginPage {
        id
      }
      brandPage {
        id
      }
      basketPage {
        id
      }
      ovsPage {
        id
      }
      orderDetailsPage {
        id
      }
      ordersListPage {
        id
      }
      passwordChangePage {
        id
      }
      productPage {
        id
      }
      productsPage {
        id
      }
      registerPage {
        id
      }
      resetPasswordPage {
        id
      }
      searchPage {
        id
      }
      segmentsPage {
        id
      }
      onnshopWalletPage{
        id
      }
      seriesPage {
        id
      }
      transactionSummaryPage {
        id
      }
      userB2bHomePage {
        id
      }
      userB2cHomePage {
        id
      }
      userAccountPage {
        id
      }
      unauthorizedUserPage {
        id
      }
      registerPageForB2b {
        id
      }
      registerPageForB2c {
        id
      }
      invoiceDetailsPage {
        id
      }
      clientStatusesPage{
        id
      }
      invoicesListPage {
        id
      }
      receivablesListPage {
        id
      }
      onntopHomepage {
        id
      }
      workersLoginPage {
        id
      }
      balancesAndStatisticsPage {
        id
      }
      boilersInstallationPage {
        id
      }
      bonusAgreementsPage {
        id
      }
      inquiriesPage {
        id
      }
      offersPage {
        id
      }
      offerPage {
        id
      }
      inquiryPage {
        id
      }
      clipboardPage {
        id
      }
      regulationPage {
        id
      }
      pageNotFoundInThisLanguage {
        id
      }
      authorizedPersonsPage {
        id
      }
      loginInBasketPage {
        id
      }
      deliveryCostsPage {
        id
      }
      productsReviewsPage {
        id
      }
      informationAboutSorting{
        id
      }
    }
  }
`;

// TODO: Add homePageForAuthenticatedUser in second phase of project
const createOnnshopPagesRoutesParamsDataQuery = () => `
  query OnnshopPagesRoutes {
    route {
      homePage {
        id
      }
      productPage {
        id
      }
      productsPage {
        id
      }
      brandPage {
        id
      }
      seriesPage {
        id
      }
      basketPage {
        id
      }
      transactionSummaryPage {
        id
      }
      orderDetailsPage {
        id
      }
      invoiceDetailsPage {
        id
      }
      receivablesListPage {
        id
      }
      offerPage {
        id
      }
      inquiryPage{
        id
      }
    }
  }
`;

const createOnnshopArticlesCategoriesDataQuery = () => `
  query OnnshopArticlesCategories {
    allArticleCategories {
      page {
        id
      }
    }
  }
`;

const getOnnshopPagesIdsMap = async (cmsApiUrl, cmsReadonlyApiToken) => {
  const { route } = await requestDataFromDatoCMS(
    createOnnshopPagesRoutesParamsDataQuery(),
    cmsApiUrl,
    cmsReadonlyApiToken,
  );
  const onnshopPagesIdsMap = new Map();

  Object.entries(route).forEach(([pageName, { id }]) => onnshopPagesIdsMap.set(pageName, id));

  return onnshopPagesIdsMap;
};

const getCoreRoutesIds = async (cmsApiUrl, cmsReadonlyApiToken) => {
  const { route } = await requestDataFromDatoCMS(createCoreRoutesIdsQuery(), cmsApiUrl, cmsReadonlyApiToken);

  const coreRoutesIds = Object.entries(route).reduce((coreRoutesInfo, [coreRoutePage, { id }]) => {
    coreRoutesInfo[coreRoutePage] = id;
    return coreRoutesInfo;
  }, {});
  return coreRoutesIds;
};

const getAllDatoPagesForCurrentLocale = async (localeCode, cmsApiUrl, cmsReadonlyApiToken) => {
  const {
    _allPagesMeta: { count },
  } = await requestDataFromDatoCMS(createAllLocalePagesCountQuery(localeCode), cmsApiUrl, cmsReadonlyApiToken);

  const numberOfRequestsNeeded = Math.ceil(count / MAX_PAGES_COUNT_FOR_SINGLE_QUERY);

  const datoGraphQLQueries = [...Array(numberOfRequestsNeeded)].map((_, i) =>
    createAllLocalePagesDataQuery(localeCode, MAX_PAGES_COUNT_FOR_SINGLE_QUERY, MAX_PAGES_COUNT_FOR_SINGLE_QUERY * i),
  );

  let dataRequestsResults;
  let areAllResultsValid = false;

  do {
    dataRequestsResults = await Promise.all(
      datoGraphQLQueries.map((query) => requestDataFromDatoCMS(query, cmsApiUrl, cmsReadonlyApiToken)),
    );

    areAllResultsValid = dataRequestsResults.every((result) => result && Array.isArray(result.allPages));

    if (!areAllResultsValid) {
      /* eslint-disable-next-line */
      console.warn('One or more data requests returned invalid results, retrying...');
    }
  } while (!areAllResultsValid);

  return dataRequestsResults.reduce((allPagesCollection, { allPages }) => {
    allPagesCollection.push(...allPages);
    return allPagesCollection;
  }, []);
};
const fetchRoutingDataForLocale = async (localeCode, cmsApiUrl, cmsReadonlyApiToken) => {
  const allPagesFromDato = await getAllDatoPagesForCurrentLocale(localeCode, cmsApiUrl, cmsReadonlyApiToken);

  const allPages = [];

  allPagesFromDato.forEach((page) => {
    // NOTE: only not folder accepted
    if (page.isFolder) {
      return;
    }

    // NOTE: only page of type home can not have slug (null/"" [empty string]);
    if (!page.slug && !['homePage', 'homePageForUser'].includes(page.layout)) {
      return;
    }

    const hasParent = page.parent != null;
    const parentIsFolder = hasParent && page.parent.isFolder;

    if (parentIsFolder) {
      allPages.push({
        ...page,
        parent: {
          id: null,
        },
      });
    } else {
      allPages.push({
        ...page,
      });
    }
  });

  return allPages;
};

const getOnnshopProductPageRouteParamsMap = async (cmsApiUrl, cmsReadonlyApiToken) => {
  const onnshopPagesIdsMap = await getOnnshopPagesIdsMap(cmsApiUrl, cmsReadonlyApiToken);
  const routeParamsMap = new Map();

  // NOTE: Product page - setting id (key) to param url (value)
  routeParamsMap.set(onnshopPagesIdsMap.get('productPage'), paramsConfig.product.paramPath);

  return routeParamsMap;
};

const getOnnshopArticlesPagesRouteParamsMap = async (cmsApiUrl, cmsReadonlyApiToken) => {
  const { allArticleCategories } = await requestDataFromDatoCMS(
    createOnnshopArticlesCategoriesDataQuery(),
    cmsApiUrl,
    cmsReadonlyApiToken,
  );

  const onnshopPagesIdsMap = new Map();
  allArticleCategories.forEach(({ page }) => onnshopPagesIdsMap.set(page.id, paramsConfig.article.paramPath));
  return onnshopPagesIdsMap;
};

const getOnnshopCategoryPageRouteParamsMap = async (cmsApiUrl, cmsReadonlyApiToken) => {
  const onnshopPagesIdsMap = await getOnnshopPagesIdsMap(cmsApiUrl, cmsReadonlyApiToken);
  const routeMainCategoryParamsMap = new Map();

  // NOTE: Product page - setting id (key) to param url (value)
  routeMainCategoryParamsMap.set(onnshopPagesIdsMap.get('productsPage'), paramsConfig.category.paramPath);

  return routeMainCategoryParamsMap;
};

const getOnnshopBrandPageRouteParamsMap = async (cmsApiUrl, cmsReadonlyApiToken) => {
  const onnshopPagesIdsMap = await getOnnshopPagesIdsMap(cmsApiUrl, cmsReadonlyApiToken);
  const routeMainCategoryParamsMap = new Map();

  // NOTE: Product page - setting id (key) to param url (value)
  routeMainCategoryParamsMap.set(onnshopPagesIdsMap.get('brandPage'), paramsConfig.brand.paramPath);

  return routeMainCategoryParamsMap;
};

const getOnnshopOrderDetailPageRouteParamsMap = async (cmsApiUrl, cmsReadonlyApiToken) => {
  const onnshopPagesIdsMap = await getOnnshopPagesIdsMap(cmsApiUrl, cmsReadonlyApiToken);
  const routeParamsMap = new Map();

  // NOTE: Product page - setting id (key) to param url (value)
  routeParamsMap.set(onnshopPagesIdsMap.get('orderDetailsPage'), paramsConfig.order.paramPath);

  return routeParamsMap;
};

const getOnnshopSeriesPageRouteParamsMap = async (cmsApiUrl, cmsReadonlyApiToken) => {
  const onnshopPagesIdsMap = await getOnnshopPagesIdsMap(cmsApiUrl, cmsReadonlyApiToken);
  const routeMainCategoryParamsMap = new Map();

  // NOTE: Product page - setting id (key) to param url (value)
  routeMainCategoryParamsMap.set(onnshopPagesIdsMap.get('seriesPage'), paramsConfig.series.paramPath);

  return routeMainCategoryParamsMap;
};

const getOnnshopInvoiceDetailPageRouteParamsMap = async (cmsApiUrl, cmsReadonlyApiToken) => {
  const onnshopPagesIdsMap = await getOnnshopPagesIdsMap(cmsApiUrl, cmsReadonlyApiToken);
  const routeParamsMap = new Map();

  // NOTE: Product page - setting id (key) to param url (value)
  routeParamsMap.set(onnshopPagesIdsMap.get('invoiceDetailsPage'), paramsConfig.invoice.paramPath);

  return routeParamsMap;
};

const getOnnshopOfferPageRouteParamsMap = async (cmsApiUrl, cmsReadonlyApiToken) => {
  const onnshopPagesIdsMap = await getOnnshopPagesIdsMap(cmsApiUrl, cmsReadonlyApiToken);
  const routeParamsMap = new Map();

  routeParamsMap.set(onnshopPagesIdsMap.get('offerPage'), paramsConfig.offer.paramPath);

  return routeParamsMap;
};

const getOnnshopInquiryPageRouteParamsMap = async (cmsApiUrl, cmsReadonlyApiToken) => {
  const onnshopPagesIdsMap = await getOnnshopPagesIdsMap(cmsApiUrl, cmsReadonlyApiToken);
  const routeParamsMap = new Map();

  routeParamsMap.set(onnshopPagesIdsMap.get('inquiryPage'), paramsConfig.inquiry.paramPath);

  return routeParamsMap;
};

const createMapWithDatoPageIdKeysAndParamPathValues = async (cmsApiUrl, cmsReadonlyApiToken) => {
  const onnshopPagesRouteParamsMap = await getOnnshopProductPageRouteParamsMap(cmsApiUrl, cmsReadonlyApiToken);
  const onnshopArticlesPagesRouteParamsMap = await getOnnshopArticlesPagesRouteParamsMap(
    cmsApiUrl,
    cmsReadonlyApiToken,
  );
  const onnshopCategoryPageRouteParamsMap = await getOnnshopCategoryPageRouteParamsMap(cmsApiUrl, cmsReadonlyApiToken);
  const onnshopBrandPageRouteParamsMap = await getOnnshopBrandPageRouteParamsMap(cmsApiUrl, cmsReadonlyApiToken);
  const onnshopOrderDetailPageRouteParamsMap = await getOnnshopOrderDetailPageRouteParamsMap(
    cmsApiUrl,
    cmsReadonlyApiToken,
  );
  const onnshopSeriesPageRouteParamsMap = await getOnnshopSeriesPageRouteParamsMap(cmsApiUrl, cmsReadonlyApiToken);
  const onnshopInvoiceDetailPageRouteParamsMap = await getOnnshopInvoiceDetailPageRouteParamsMap(
    cmsApiUrl,
    cmsReadonlyApiToken,
  );
  const onnshopOfferPageRouteParamsMap = await getOnnshopOfferPageRouteParamsMap(cmsApiUrl, cmsReadonlyApiToken);
  const onnshopInquiryPageRouteParamsMap = await getOnnshopInquiryPageRouteParamsMap(cmsApiUrl, cmsReadonlyApiToken);

  return new Map([
    ...onnshopPagesRouteParamsMap,
    ...onnshopArticlesPagesRouteParamsMap,
    ...onnshopCategoryPageRouteParamsMap,
    ...onnshopBrandPageRouteParamsMap,
    ...onnshopOrderDetailPageRouteParamsMap,
    ...onnshopSeriesPageRouteParamsMap,
    ...onnshopInvoiceDetailPageRouteParamsMap,
    ...onnshopOfferPageRouteParamsMap,
    ...onnshopInquiryPageRouteParamsMap,
  ]);
};

module.exports = {
  fetchRoutingDataForLocale,
  createMapWithDatoPageIdKeysAndParamPathValues,
  getCoreRoutesIds,
};
