const { defaultLanguage } = require('../config/locales');
const routes = require('next-routes');

const {
  fetchRoutingDataForLocale,
  createMapWithDatoPageIdKeysAndParamPathValues,
  getCoreRoutesIds,
} = require('./datoConfiguration');

// NOTE: Used as a cache to limit DatoCMS requests during client-side navigation
let cachedRoutingMapsResults = null;
let cachedCoreRoutesIds = null;

const createRoutesAndSlugsMaps = (allLangsPagesDict, coreRoutesIds) => {
  const routesMap = new Map();
  const slugsMap = new Map();
  const slugsMapByLocale = {};

  Object.entries(allLangsPagesDict).forEach(([localeCode, localeRoutes]) => {
    localeRoutes.forEach(({ id, title, layout, slug: initialSlug, parent }) => {
      const isOtherLanguage = localeCode !== defaultLanguage;
      let slug = null;

      if (isOtherLanguage && initialSlug === '') {
        slug = localeCode;
      } else if (isOtherLanguage) {
        slug = `${localeCode}/${initialSlug}`;
      } else {
        slug = initialSlug;
      }

      const name = {
        [localeCode]: slug,
      };

      const pattern = {
        [localeCode]: `/${slug}`,
      };

      const pageTitle = {
        [localeCode]: title,
      };

      slugsMap.set(slug, id);

      if (!slugsMapByLocale[localeCode]) {
        slugsMapByLocale[localeCode] = new Map();
      }
      slugsMapByLocale[localeCode].set(slug, id);

      if (!routesMap.get(id)) {
        routesMap.set(id, {
          parentId: parent && parent.id ? parent.id : null,
          layout,
          name,
          pattern,
          pageTitle,
          id,
        });
      } else {
        const currentRoute = routesMap.get(id);

        routesMap.set(id, {
          ...currentRoute,
          name: {
            ...currentRoute.name,
            ...name,
          },
          pattern: {
            ...currentRoute.pattern,
            ...pattern,
          },
          pageTitle: {
            ...currentRoute.pageTitle,
            ...pageTitle,
          },
        });
      }
    });
  });
  const coreRoutesInfo = Object.entries(coreRoutesIds).reduce((coreRoutesInfoDict, [coreRoutePage, corePageId]) => {
    const routeInfo = routesMap.get(corePageId);
    if (typeof routeInfo !== 'undefined') {
      coreRoutesInfoDict[coreRoutePage] = routeInfo;
    }
    return coreRoutesInfoDict;
  }, {});

  return {
    coreRoutesInfo,
    routesMap,
    slugsMap,
    slugsMapByLocale,
  };
};

const prepareRoutingMaps = async (availableLocaleCodes, cmsApiUrl, cmsReadonlyApiToken) => {
  const isBrowser = typeof window !== 'undefined';

  const getPagesData = async () => {
    const allLocalesPagesDataGetters = availableLocaleCodes.map((localeCode) =>
      fetchRoutingDataForLocale(localeCode, cmsApiUrl, cmsReadonlyApiToken),
    );

    const allPromisesResults = await Promise.all([
      getCoreRoutesIds(cmsApiUrl, cmsReadonlyApiToken),
      ...allLocalesPagesDataGetters,
    ]);

    return { coreRoutesIds: allPromisesResults[0], routingMaps: allPromisesResults.slice(1) };
  };

  if (!isBrowser) {
    const { routingMaps, coreRoutesIds } = await getPagesData();
    cachedRoutingMapsResults = routingMaps;
    cachedCoreRoutesIds = coreRoutesIds;
  }

  if (isBrowser && !cachedRoutingMapsResults) {
    const { routingMaps, coreRoutesIds } = await getPagesData();
    cachedRoutingMapsResults = routingMaps;
    cachedCoreRoutesIds = coreRoutesIds;
  }

  const allLocalesPagesDict = {};

  availableLocaleCodes.forEach((localeCode, localeCodeIdx) => {
    allLocalesPagesDict[localeCode] = cachedRoutingMapsResults[localeCodeIdx];
  });

  return createRoutesAndSlugsMaps(allLocalesPagesDict, cachedCoreRoutesIds);
};

const prepareRouting = async (availableLocaleCodes, nextRoutesSchema, cmsApiUrl, cmsReadonlyApiToken) => {
  const router = routes();

  const mapWithDatoPageIdKeysAndParamPathValues = await createMapWithDatoPageIdKeysAndParamPathValues(
    cmsApiUrl,
    cmsReadonlyApiToken,
  );

  availableLocaleCodes.forEach((localeCode) => {
    nextRoutesSchema.forEach(({ name, pattern, layout, id }) => {
      const currentLangName = name[localeCode];
      const currentLangPattern = pattern[localeCode];
      const routeExist = router.routes.filter((route) => route.name === currentLangName);
      const routeParamPath = mapWithDatoPageIdKeysAndParamPathValues.get(id) || '';

      if (!routeExist.length) {
        router.add({
          name: currentLangName,
          pattern: `${currentLangPattern}${routeParamPath}`,
          page: layout,
        });
      }
    });
  });

  return router;
};

// TODO: Should definitely involve more logic here ;)
const getSlugFromUrl = (url) =>
  url
    .slice(1)
    .split('?')[0]
    .replace(/#[\w]+/gim, '');

const getBreadcrumbsHierarchy = (locale, routesMap, firstPageId) => {
  const hierarchy = [];
  let pageId = firstPageId;

  let currentPage = routesMap.get(pageId);
  while (pageId !== null && typeof currentPage !== 'undefined') {
    const { pageTitle, pattern, layout } = currentPage;

    hierarchy.unshift({
      name: pageTitle[locale],
      url: pattern[locale],
      layout,
    });

    pageId = currentPage.parentId;

    if (pageId !== null) {
      currentPage = routesMap.get(pageId);
    }
  }

  return hierarchy;
};

module.exports = {
  prepareRoutingMaps,
  prepareRouting,
  getSlugFromUrl,
  getBreadcrumbsHierarchy,
};
